import { useDeviceSelectors } from "react-device-detect"

import { GetStartedFlow } from "@/components/modals/flows/GetStarted"
import { JoinSpaceFlow } from "@/components/modals/flows/JoinSpace"
import { ProSpaceFlow } from "@/components/modals/flows/ProSpace"

export function GlobalModals() {
  // This component never re-renders, so this is stable even when the UA changes.
  const [{ isDesktop }] = useDeviceSelectors(window.navigator.userAgent)

  return (
    <>
      <ProSpaceFlow />
      <JoinSpaceFlow />
      {isDesktop ? <GetStartedFlow /> : null}
    </>
  )
}
