import type { SIDEBAR_ENVIRONMENT } from "@bcmi-labs/cloud-sidebar"
import { CloudContextProvider } from "@bcmi-labs/cloud-sidebar"

import { useRecap } from "@/lib/hooks/useRecap"
import { useSpace } from "@/lib/hooks/useSpace"
import { SpaceStorageInstance } from "@/lib/services/instances"

const SIDEBAR_ENV: typeof SIDEBAR_ENVIRONMENT = {
  DIGITAL_STORE_URL: import.meta.env.VITE_STORE,
  ID_URL: import.meta.env.VITE_ID_URL,
  ICON_CDN: import.meta.env.VITE_CDN,
  CLOUD_HOME_URL: ""
}

// Handle the cloud context for the sidebar, but wire it with our internal state management.
export function CloudContext(props: { children: React.ReactNode }) {
  const { spaceList } = useSpace()
  const { userRecap } = useRecap()

  return (
    <CloudContextProvider
      spaces={spaceList.data}
      // TODO: Fix this by making it optional
      onSpaceChange={() => {}}
      privateSpacePlans={userRecap.data?.plans || []}
      storage={SpaceStorageInstance}
      env={SIDEBAR_ENV}>
      {props.children}
    </CloudContextProvider>
  )
}
