import { BaseModal, Loader, ModalBody, ModalContent, ModalHeader } from "@bcmi-labs/art-ui/components"
import { createContext, Suspense, useContext, useEffect, useState } from "react"

// TODO: This stuff is a big work in progress. I'm not sure if this is the best way to do it.

// ? Is this a hack? I am not sure. However now you can type the flow consumer component. Just don't fuck up when you're using it.
export type FlowContext<T extends Record<string, unknown> = NonNullable<unknown>> = {
  /** Close the flow. */
  close: () => void
} & T

// See: https://timdeschryver.dev/bits/pretty-typescript-types
type Prettify<T> = {
  [K in keyof T]: T[K]
} & unknown

export type FlowActions<T = undefined> = {
  /** Previous flow */
  prev?: () => void
  /** Next flow */
  // IF T is undefined, args is optional, else required
  next: T extends undefined ? (args?: T) => void : (args: T) => void
}

// Ensure that components extend the needed set.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FlowPropsMapper<T extends Record<string, React.ComponentType<any>>> = {
  [K in keyof T]: T[K] extends React.ComponentType<infer P>
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      P extends FlowActions<any>
      ? // ? Unsure about this, but the prettify makes types easier to hover!
        Prettify<React.ComponentProps<T[K]>>
      : never
    : never
}

/**
 * Stub function to throw an error if the flow context is not provided.
 * @ignore
 */
const stub = () => {
  throw new Error("You forgot to wrap your component in <FlowContext>.")
}

export const defaultFlowContext: FlowContext = {
  close: stub
}

export const FlowContext = createContext<FlowContext>(defaultFlowContext)

/**
 * Custom hook to access the flow context. Allows you to navigate between steps in the flow.
 */
export function useFlow<T extends Record<string, unknown>>() {
  const context = useContext(FlowContext) as FlowContext<T>
  if (context === undefined) {
    throw new Error("useFlow must be used within a FlowContext")
  }
  return context
}

/**
 * Common flow manager component. Handles the modal state and provides the flow context for dialog components.
 */
export function FlowDialogManager<T extends Record<string, unknown>>(props: {
  /**
   * If the flow is open or not.
   */
  open: boolean
  /**
   * Cleanup function to call when the flow is closed.
   * This should be used to reset the flow state (e.g. wiping the flow step)
   */
  cleanup: () => void
  /**
   * Flow content (typically a <ModalContent>)
   */
  children: React.ReactNode
  /**
   * Context to pass to the flow.
   */
  ctx?: T
}) {
  const { open, cleanup, children, ctx } = props

  // Track the inner state of the modal. This should be used to track the flow state.
  const [openFlow, setOpenFlow] = useState(false)

  // ?: Wish we didn't have to use an effect for this.. i'm open to suggestions, especially if you get useSyncExternalStore to work (i couldn't).
  // Set the open state of the modal to true if the flow is set. This way we decouple modal state from flow state.
  useEffect(() => {
    setOpenFlow(p => (!open ? false : p || !!open))
  }, [open])

  // TODO: Please wrap the <Suspense> in a ErrorBoundary component: we're trading type-safety for a better DX and UX, but we should still handle errors gracefully.
  return (
    <FlowContext.Provider value={{ ...ctx, close: () => setOpenFlow(false) }}>
      <BaseModal
        open={openFlow}
        onOpenChange={v => setOpenFlow(v)}
        contentProps={{
          onCloseAutoFocus: cleanup
        }}>
        {open ? (
          <Suspense
            fallback={
              <ModalContent style={{ minHeight: "300px" }}>
                <ModalHeader title="Loading flow..." />
                <ModalBody>
                  <div className="flex-center">
                    <Loader />
                  </div>
                </ModalBody>
              </ModalContent>
            }>
            {children}
          </Suspense>
        ) : null}
      </BaseModal>
    </FlowContext.Provider>
  )
}
