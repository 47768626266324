import { lazyPrefetch } from "@bcmi-labs/art-ui/utils"
import { Fragment, useEffect, useState } from "react"

import type { FlowPropsMapper } from "@/components/modals/flows/flow"
import { FlowDialogManager } from "@/components/modals/flows/flow"
import { useModalStore } from "@/store/modals"

// Lazy load all the flow steps.
const JoinSpaceModal = lazyPrefetch(() => import("@/components/modals/flows/JoinSpace/JoinSpaceModal"))
const JoinFlowStartModal = lazyPrefetch(() => import("@/components/modals/flows/JoinSpace/JoinFlowStartModal"))
const JoinFlowFinishModal = lazyPrefetch(() => import("@/components/modals/flows/JoinSpace/JoinFlowFinishModal"))
const JoinErrorModal = lazyPrefetch(() => import("@/components/modals/flows/JoinSpace/JoinErrorModal"))

export const joinSpaceflowSteps = {
  join: JoinSpaceModal,
  start: JoinFlowStartModal,
  end: JoinFlowFinishModal,
  error: JoinErrorModal
} as const

// Declare context methods for each flow step.
const propsMap: FlowPropsMapper<typeof joinSpaceflowSteps> = {
  join: {
    next: () => useModalStore.getState().setJoinSpaceFlowStep("start")
  },
  start: { next: () => useModalStore.getState().setJoinSpaceFlowStep("end") },
  end: {
    next: () => useModalStore.getState().setJoinSpaceFlowStep(undefined)
  },
  error: {
    next: () => useModalStore.getState().setJoinSpaceFlowStep(undefined)
  }
}

/**
 * JoinSpaceFlow manager
 */
export function JoinSpaceFlow() {
  const joinSpaceFlow = useModalStore(s => s.joinSpaceFlow)
  const setJoinSpaceFlowStep = useModalStore(s => s.setJoinSpaceFlowStep)
  const [prefetched, setPrefetched] = useState(false)

  const Comp = joinSpaceFlow?.step ? joinSpaceflowSteps[joinSpaceFlow.step] : Fragment

  useEffect(() => {
    if (joinSpaceFlow.step && !prefetched) {
      Promise.all(Object.values(joinSpaceflowSteps).map(step => step.prefetch())).then(() => setPrefetched(true))
    }

    return undefined
  }, [joinSpaceFlow.step, prefetched])

  return (
    <FlowDialogManager open={!!joinSpaceFlow?.step} cleanup={() => setJoinSpaceFlowStep(undefined)}>
      {joinSpaceFlow.step ? <Comp {...propsMap[joinSpaceFlow.step]} /> : null}
    </FlowDialogManager>
  )
}
