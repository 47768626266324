import { createFileRoute } from "@tanstack/react-router"

import { routeGuard } from "@/lib/routing/beforeLoad"

type SearchParams = {
  kit?: string
}

export const Route = createFileRoute("/_authlayout/_none/registration/")({
  staticData: {
    title: "Kit Registration"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context, { allowMinor: true })
  },
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    kit: search.kit ? String(search.kit) : undefined
  })
})
