import { createFileRoute, redirect } from "@tanstack/react-router"

import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_none/tos-acceptance/")({
  staticData: {
    title: "TOS Acceptance"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)

    const customization = context.authClient.getCustomization()

    if (!customization) {
      redirect({
        to: "/",
        throw: true
      })
    }

    // Perform additional checks after customization is loaded, to avoid throwing
    if (await context.authClient.checkTosAcceptance()) {
      redirect({
        to: "/",
        throw: true
      })
    }
  }
})
