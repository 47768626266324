import { useAuth } from "@bcmi-labs/art-auth"
import { snackbar } from "@bcmi-labs/art-ui/components"
import { useCloudContext } from "@bcmi-labs/cloud-sidebar"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useMemo, useSyncExternalStore } from "react"

import { disbandSpace, leaveSpace, updateSpace } from "@/api/classroom"
import { query } from "@/api/query"
import { parseError } from "@/api/utils/NetworkError"
import { Roles } from "@/constants/roles"
import { MY_SPACE } from "@/constants/spaces"
import { type Space } from "@/types"

/** A hook that returns the selected space from the store as a stateful value. */
export function useSpace() {
  const { storage } = useCloudContext()
  const spaceId = useSyncExternalStore(
    cb => {
      storage.subscribe(cb)
      return () => storage.unsubscribe(cb)
    },
    () => storage.getSpace(),
    () => MY_SPACE.id
  )

  const { user } = useAuth()

  // Return an empty array when undefined, to avoid errors when mapping over the space list.
  /**
   * Get the list of spaces for the current user
   */
  const spaceList = useQuery({
    ...query.space.list,
    staleTime: Infinity
  })

  /**
   * Get the selected space
   */
  const space = spaceList.data?.find(sp => sp.id === spaceId) || (MY_SPACE as Space)

  /*
   * ====================
   * Space Details
   * - These are derived from the selected space and are used to display information about the space.
   * ====================
   */

  /**
   * Returns how many member seats are still available for the current space. If unlimited, returns 9999
   */
  const availableMemberSeats = useMemo(() => {
    const { plan_id, plan_seats, members, space: spaceType } = space
    // Pro spaces have no member limit
    if (spaceType === "pro") return 9999
    // Free edu spaces have no member limit
    return !plan_id ? 9999 : (plan_seats || 0) - (members || 0)
  }, [space])

  /**
   * Returns the available roles for the current space
   */
  const spaceRoles = useMemo(() => {
    let options: { value: string; label: string }[] = []

    if (space.space === "edu") {
      options = [
        { value: Roles["role:edu-admin"], label: "Admin" },
        { value: Roles["role:edu-teacher"], label: "Teacher" },
        { value: Roles["role:edu-student"], label: "Student" }
      ]
    }
    if (space.space === "pro") {
      return [
        { value: Roles["role:pro-admin"], label: "Admin" },
        { value: Roles["role:pro-editor"], label: "Editor" }
      ]
    }

    return options
  }, [space.space])

  /*
   * ====================
   * Space Actions
   * - These mutations are triggered by user actions on the currently selected space.
   * ====================
   */

  const updateSpaceMutation = useMutation({
    mutationFn: (payload: Parameters<typeof updateSpace>[0]) => updateSpace(payload, spaceId),
    onSuccess: () => {
      spaceList.refetch()
      snackbar({
        message: "Space information updated",
        variant: "success"
      })
    },
    onError(error) {
      snackbar({
        message: `Network Error: ${parseError(error).body.detail}`,
        variant: "success"
      })
    }
  })

  const leaveSpaceMutation = useMutation({
    mutationFn: () => leaveSpace(spaceId, user["http://arduino.cc/id"]),
    onSuccess: () => {
      spaceList.refetch()
      storage.setSpace(MY_SPACE.id)
      snackbar({
        message: `You left ${space.name}`,
        variant: "success"
      })
    },
    onError(error) {
      snackbar({
        message: `Network Error: ${parseError(error).body.detail}`,
        variant: "success"
      })
    }
  })

  const disbandSpaceMutation = useMutation({
    mutationFn: () => disbandSpace(spaceId),
    onSuccess: () => {
      spaceList.refetch()
      storage.setSpace(MY_SPACE.id)
      snackbar({
        message: `You disbanded ${space.name}`,
        variant: "success"
      })
    },
    onError(error) {
      snackbar({
        message: `Network Error: ${parseError(error).body.detail}`,
        variant: "success"
      })
    }
  })

  return {
    spaceList,
    space,
    availableMemberSeats,
    spaceRoles,
    updateSpaceMutation,
    leaveSpaceMutation,
    disbandSpaceMutation
  }
}
