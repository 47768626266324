import { unwrapResponse } from "@/api/utils/NetworkError"
import type { Member, Space } from "@/types"

import { authFetch } from "./utils"

export async function createSpace(payload: Partial<Space>) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/users/v1/organizations`, {
    method: "POST",
    body: JSON.stringify({ ...payload })
  })
  return unwrapResponse<Space>(res)
}

export async function updateUserName(payload: Member["name"], userID?: Member["id"], isMinor?: boolean) {
  if (!(payload && userID)) return null

  if (isMinor) {
    const res = await authFetch(`${import.meta.env.VITE_API2_URL}/users/v1/children/byID/${userID}/name`, {
      method: "PUT",
      body: JSON.stringify({
        name: payload
      })
    })
    return unwrapResponse(res)
  }

  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/users/v1/users/byID/${userID}/private`, {
    method: "PUT",
    body: JSON.stringify({
      name: payload
    })
  })

  return unwrapResponse(res)
}

export async function getUserByID(userID: Member["id"], isMinor?: boolean) {
  const res = await authFetch(
    `${import.meta.env.VITE_API2_URL}/users/v1/${isMinor ? "children/byID/me" : `users/byID/${userID}?scopes=private`}`
  )
  return unwrapResponse<{
    id: string
    username: string
    private: Record<string, string>
  }>(res)
}

export async function getUserSettings() {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/users/v1/users/settings`)
  return unwrapResponse<Record<string, unknown>>(res)
}

export async function validateSpaceCode(code: string) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/users/v1/organizations/validate?code=${code}`)
  return unwrapResponse<{ available: boolean; organization_id: string }>(res)
}
