import { authFetch, getXOrganizationHeaders } from "@/api/utils"
import { unwrapResponse } from "@/api/utils/NetworkError"
import type { BrandingCustomization } from "@/types"

type CustomizationPayload = Pick<BrandingCustomization, "social_logins" | "url" | "custom_tos" | "policy_url">

export async function getCustomization(spaceId: string) {
  const response = await authFetch(`${import.meta.env.VITE_API2_URL}/customization/v1/customizable/ofOrg/${spaceId}`)

  return unwrapResponse<BrandingCustomization>(response)
}

export async function isCustomizationUrlAvailable(fourthDomain: string) {
  const response = await authFetch(
    `${import.meta.env.VITE_API2_URL}/customization/v1/customizable/checkURL/${fourthDomain}`
  )
  return unwrapResponse<boolean>(response)
}

export async function createCustomization(spaceId: string, payload: CustomizationPayload) {
  const response = await authFetch(`${import.meta.env.VITE_API2_URL}/customization/v1/customizable/create`, {
    method: "POST",
    headers: getXOrganizationHeaders(spaceId),
    body: JSON.stringify(payload)
  })
  return unwrapResponse<BrandingCustomization>(response)
}

export async function updateCustomization(space_id: string, payload: CustomizationPayload) {
  const response = await authFetch(`${import.meta.env.VITE_API2_URL}/customization/v1/customizable/update`, {
    method: "PUT",
    headers: getXOrganizationHeaders(space_id),
    body: JSON.stringify(payload)
  })
  return unwrapResponse<BrandingCustomization>(response)
}
