import { createFileRoute } from "@tanstack/react-router"

import { routeGuard } from "@/lib/routing/beforeLoad"

type SearchParams = {
  spaceType: "edu"
}

// TODO: Improve validation
export const Route = createFileRoute("/_authlayout/_none/space/")({
  staticData: {
    title: "New Space"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    spaceType: search.spaceType as "edu"
  })
})
