// Legacy page to flag chromebook app users coming from the Chrome Web Store.
// See: https://github.com/bcmi-labs/iot-cloud.arduino.cc/blob/main/src/views/installPWAChromeOS/InstallPWAChromeOS.tsx

import { createFileRoute, redirect } from "@tanstack/react-router"

export const Route = createFileRoute("/chrome-app")({
  beforeLoad: () => {
    // Set legacy flag to true
    localStorage.setItem("create.installed-from-store", "true")
    // New, session-bound flag
    sessionStorage.setItem("arduino::chrome-app", "true")

    redirect({
      to: "/",
      throw: true,
      replace: true
    })
  }
})
