import type { AnyRouter } from "@tanstack/react-router"
import { lazy, Suspense, useEffect, useState } from "react"

/*
 * We're intentionally loading the production version of the devtools here, so we don't have to discriminate between
 * development and production builds in our codebase.
 */
const ReactQueryDevtools = lazy(() =>
  import("@tanstack/react-query-devtools/build/modern/production.js").then(d => ({
    default: d.ReactQueryDevtools
  }))
)

const TanStackRouterDevtools = lazy(() =>
  import("@tanstack/router-devtools").then(res => ({
    default: res.TanStackRouterDevtools
  }))
)

/**
 * Global manager for all devtools.
 */
export function Devtools(props: { router: AnyRouter }) {
  const [showDevtools, setShowDevtools] = useState(import.meta.env.DEV)

  useEffect(() => {
    window.toggleDevtools = () => setShowDevtools(old => !old)
  }, [])

  return showDevtools ? (
    <Suspense fallback={null}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom" />
      <TanStackRouterDevtools
        router={props.router}
        toggleButtonProps={{
          style: {
            left: "unset",
            bottom: "20px",
            right: "65px"
          }
        }}
      />
    </Suspense>
  ) : null
}
