import { authFetch, getXOrganizationHeaders } from "@/api/utils"
import { unwrapResponse } from "@/api/utils/NetworkError"
import type { Device, Ota, Space } from "@/types"

export async function getOtaListByDeviceID(id: Space["id"], deviceId: Device["id"]) {
  const params = new URLSearchParams({ device_id: deviceId, limit: "1000", order: "desc" })
  const res = await authFetch(`${import.meta.env.VITE_API_URL}/ota/v1/ota?${params}`, {
    headers: getXOrganizationHeaders(id)
  })

  return unwrapResponse<{
    ota: Ota[]
  }>(res)
}
