import { Loader, ModalBody, ModalContent, ModalHeader } from "@bcmi-labs/art-ui/components"
import { lazyPrefetch } from "@bcmi-labs/art-ui/utils"
import type Daemon from "arduino-create-agent-js-client"
import { useEffect, useState } from "react"

import { getBoardDetail } from "@/api/common"
import type { FlowPropsMapper } from "@/components/modals/flows/flow"
import { FlowDialogManager } from "@/components/modals/flows/flow"
import { useModalStore } from "@/store/modals"

// Lazy load all the flow steps.
const GetStarted = lazyPrefetch(() => import("@/components/modals/flows/GetStarted/GetStarted"))
const Installing = lazyPrefetch(() => import("@/components/modals/flows/GetStarted/Installing"))
const DownloadAgent = lazyPrefetch(() => import("@/components/modals/flows/GetStarted/DownloadAgent"))
const MobileQR = lazyPrefetch(() => import("@/components/modals/flows/GetStarted/MobileQR"))
const NoDevice = lazyPrefetch(() => import("@/components/modals/flows/GetStarted/NoDevice"))
const PlugDevice = lazyPrefetch(() => import("@/components/modals/flows/GetStarted/PlugDevice"))
const DeviceError = lazyPrefetch(() => import("@/components/modals/flows/GetStarted/DeviceError"))
const DeviceDetected = lazyPrefetch(() => import("@/components/modals/flows/GetStarted/DeviceDetected"))

export const getStartedFlowSteps = {
  start: GetStarted,
  downloadagent: DownloadAgent,
  installing: Installing,
  plugdevice: PlugDevice,
  mobileqr: MobileQR,
  nodevice: NoDevice,
  devicedetected: DeviceDetected,
  deviceerror: DeviceError
} as const

// Declare context methods for each flow step.
// TODO: Maybe make this a function that takes the context as an argument, so it gets in the scope and can be used in the flow steps.
const propsMap: FlowPropsMapper<typeof getStartedFlowSteps> = {
  start: {
    next: ({ hasAgent }) => {
      useModalStore.getState().setGetStartedFlowStep(hasAgent ? "plugdevice" : "downloadagent")
    }
  },
  downloadagent: {
    prev: () => useModalStore.getState().setGetStartedFlowStep("start"),
    next: () => useModalStore.getState().setGetStartedFlowStep("installing")
  },
  installing: {
    prev: () => useModalStore.getState().setGetStartedFlowStep("downloadagent"),
    next: () => useModalStore.getState().setGetStartedFlowStep("plugdevice")
  },
  plugdevice: {
    next: async ({ device }) => {
      const board = await getBoardDetail(device)
      useModalStore.getState().setGetStartedFlowStore({ board, serial: device })
      useModalStore.getState().setGetStartedFlowStep("devicedetected")
    }
  },
  mobileqr: {
    next: () => useModalStore.getState().setGetStartedFlowStep(undefined)
  },
  nodevice: {
    next: () => useModalStore.getState().setGetStartedFlowStep("plugdevice")
  },
  devicedetected: {
    prev: () => {
      useModalStore.getState().setGetStartedFlowStore(undefined)
      useModalStore.getState().setGetStartedFlowStep("plugdevice")
    },
    next: () => useModalStore.getState().setGetStartedFlowStep(undefined)
  },
  deviceerror: {
    next: () => useModalStore.getState().setGetStartedFlowStep(undefined)
  }
}

function renderChildren(step: keyof typeof getStartedFlowSteps | undefined) {
  if (!step) return null
  const Comp = getStartedFlowSteps[step]
  // @ts-expect-error - This is fine, we're just passing the props to the component.
  return <Comp {...propsMap[step]} />
}

export type GetStartedFlowContext = {
  daemon: Daemon | null
}

/**
 * GetStartedFlow manager
 */
export function GetStartedFlow() {
  const getStartedFlow = useModalStore(s => s.getStartedFlow)
  const setGetStartedFlowStep = useModalStore(s => s.setGetStartedFlowStep)
  const setGetStartedFlowStore = useModalStore(s => s.setGetStartedFlowStore)

  const [prefetched, setPrefetched] = useState(false)

  const [daemon, setDaemon] = useState<Daemon | null>(null)

  useEffect(() => {
    if (getStartedFlow.step && !prefetched) {
      Promise.all(Object.values(getStartedFlowSteps).map(step => step.prefetch())).then(() => setPrefetched(true))
    }

    return undefined
  }, [getStartedFlow.step, prefetched])

  // ! This doesn't support the new CrOS extension with Web Serial API.
  // ! In order to add support we'll need to polyfill buffer and ensure that process.env is defined so that the Uploader can work.
  // ! Also we're setting a state inside an effect! Unconventional and incorrect!! But it works for now (could use a ref if we didn't need to re-render the component because loader)
  // Bootstrap the daemon.
  useEffect(() => {
    // TODO: If one day we'll be able to destroy the daemon, we can set the ref to null so GC can collect it. For now, keep it forever once it's created.
    async function spawnDaemon() {
      if (getStartedFlow.step && !daemon) {
        setDaemon(
          await import("arduino-create-agent-js-client").then(
            ({ default: Daemon }) =>
              new Daemon(import.meta.env.VITE_BOARDS_API_URL, {
                chromeExtensionid: import.meta.env.VITE_CHROME_EXTENSION_ID,
                useWebSerial: false
              })
          )
        )
      }
    }
    spawnDaemon()
  }, [daemon, getStartedFlow.step, setDaemon])

  return (
    <FlowDialogManager<GetStartedFlowContext>
      open={!!getStartedFlow?.step}
      ctx={{ daemon }}
      cleanup={() => {
        setGetStartedFlowStep(undefined)
        setGetStartedFlowStore(undefined)
      }}>
      {daemon ? (
        renderChildren(getStartedFlow.step)
      ) : (
        <ModalContent>
          <ModalHeader title="Loading agent..." />
          <ModalBody>
            <div className="flex-center">
              <Loader />
            </div>
          </ModalBody>
        </ModalContent>
      )}
    </FlowDialogManager>
  )
}
