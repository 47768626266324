import { redirect } from "@tanstack/react-router"

import { MY_SPACE } from "@/constants/spaces"
import type { RouterContext } from "@/router"

/**
 * A route guard that performs preloading checks for routes that
 * require authentication.
 *
 * @param context - Context object.
 * @param config - Configuration options.
 * @returns
 * @throws {Error} - If the user is not logged in.
 * @throws {Redirect} - If the user is a minor and the route does not allow minors.
 */
export async function routeGuard(
  context: RouterContext,
  config: {
    allowMinor?: boolean
    allowPrivateSpace?: boolean
    preload?: boolean
  } = {}
) {
  // Preload is currently not used, but we may need it in the future
  const { allowMinor = false, allowPrivateSpace = true, preload: _preload = false } = config

  const user = await context.authClient.getUser()
  if (!user) {
    throw new Error("You must be logged in to access this page.")
  }

  // Block access if minor
  if (!allowMinor && user["http://arduino.cc/is_minor"] === true) {
    redirect({
      to: "/",
      throw: true
    })
  }

  if (!allowPrivateSpace && context.spaceStorage.getSpace() === MY_SPACE.id) {
    redirect({
      to: "/",
      throw: true
    })
  }
}
