import { hasAuthParams, useAuth } from "@bcmi-labs/art-auth"
import { createRootRouteWithContext, Outlet, redirect, ScrollRestoration } from "@tanstack/react-router"
import { memo } from "react"

import { ErrorBoundary } from "@/components/helpers/ErrorBoundary"
import { GlobalModals } from "@/components/modals/flows"
import { MY_SPACE } from "@/constants/spaces"
import { useSpace } from "@/lib/hooks/useSpace"
import type { RouterContext } from "@/router"

/*
 * ==================
 * Application Helpers
 * ==================
 */

// Gracefully handle preload errors on fresh deployments
window.addEventListener("vite:preloadError", event => {
  const failedPath = window.location.pathname
  const now = Date.now()

  // Check if we already tried to reload the page by storing the list of failed paths and timestamp in session storage
  const previousErrors = sessionStorage.getItem("preload-error")
  const parsedErrors = previousErrors ? JSON.parse(previousErrors) : []
  if (previousErrors) {
    // If the path is already in the list, check if it's been more than 60 seconds since the last error
    const lastError = parsedErrors.find(error => error.path === failedPath)
    if (lastError) {
      const secondsSinceLastError = (now - lastError.timestamp) / 1000
      // Avoid reloading the page if the error happened less than 60 seconds ago
      if (secondsSinceLastError < 60) {
        console.error("Failed to preload:", event)
        return
      }
    }
  }
  sessionStorage.setItem("preload-error", JSON.stringify([...parsedErrors, { path: failedPath, timestamp: now }]))
  console.warn("Preload error detected, reloading page:", event)
  window.location.reload()
})

/*
 * ==================
 * Application Components
 * ==================
 */

function GlobalElements() {
  const { client } = useAuth()
  const { space } = useSpace()

  // Customized spaces don't get to enjoy the walkthrough/global modals on the private space.
  if (client.getCustomization() && space.id === MY_SPACE.id) return null

  return <GlobalModals />
}

/*
 * ==================
 * Application structure
 * ==================
 */
export default function App() {
  return (
    <div className="app">
      <Outlet />
      {/** Hidden content */}
      <ScrollRestoration />
      <GlobalElements />
    </div>
  )
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: memo(App),
  errorComponent: error => <ErrorBoundary error={error} />,
  pendingMs: 200, // Relax the pending state to avoid content flashing
  beforeLoad: async ({ context: { authClient } }) => {
    // Try prefilling token cache
    await authClient.checkSession()

    // If we're seeing auth params in the URL, we're likely coming from an external auth provider
    if (hasAuthParams() && !(await authClient.isAuthenticated())) {
      const appState = new URLSearchParams((await authClient.handleRedirectCallback()).appState)

      // Remove pathname from the query string
      const pathname = appState.get("pathname") || "/"
      if (pathname) appState.delete("pathname")

      // Get all other query params insto a key-value object
      const query = Object.fromEntries(appState)

      redirect({
        to: pathname,
        search: query,
        throw: true
      })
    }
  }
})
