import { createFileRoute } from "@tanstack/react-router"

import { query } from "@/api/query"
import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_sidebar/api-keys/")({
  staticData: {
    title: "API Keys"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: ({ context: { queryClient } }) => {
    queryClient.ensureQueryData(query.user.apiKeys)
  }
})
