// Import reset as a separate file to avoid conflicts with other styles.
import "./styles/reset.scss"
import "./styles/index.scss"

import { analyticsBootstrap, AuthProvider } from "@bcmi-labs/art-auth"
import { SnackbarProvider } from "@bcmi-labs/art-ui/components"
import { QueryClientProvider } from "@tanstack/react-query"
import { isRedirect, RouterProvider } from "@tanstack/react-router"
import { createRoot } from "react-dom/client"

import { Devtools } from "@/components/helpers/Devtools"
import { AuthClientInstance, queryClient, SpaceStorageInstance } from "@/lib/services/instances"
import { router } from "@/router"

/*
 * ==========================================
 * App Configuration
 * ==========================================
 */

// Subscribe to space changes and invalidate the router when the space changes
SpaceStorageInstance.subscribe((_, key) => {
  if (key === "space-id") {
    router.state.matches.forEach(match => {
      try {
        match.staticData.onSpaceChange?.(match.context)
      } catch (e) {
        // Check if error is a redirect
        if (isRedirect(e)) router.navigate(e)
      }
    })

    router.invalidate()
  }
})

// Gracefully handle preload errors on fresh deployments
window.addEventListener("vite:preloadError", event => {
  const failedPath = window.location.pathname
  const now = Date.now()

  // Check if we already tried to reload the page by storing the list of failed paths and timestamp in session storage
  const previousErrors = sessionStorage.getItem("preload-error")
  const parsedErrors = previousErrors ? JSON.parse(previousErrors) : []
  if (previousErrors) {
    // If the path is already in the list, check if it's been more than 60 seconds since the last error
    const lastError = parsedErrors.find(error => error.path === failedPath)
    if (lastError) {
      const secondsSinceLastError = (now - lastError.timestamp) / 1000
      // Avoid reloading the page if the error happened less than 60 seconds ago
      if (secondsSinceLastError < 60) {
        console.error("Failed to preload:", event)
        return
      }
    }
  }
  sessionStorage.setItem("preload-error", JSON.stringify([...parsedErrors, { path: failedPath, timestamp: now }]))
  console.warn("Preload error detected, reloading page:", event)
  window.location.reload()
})

/*
 * ==========================================
 * App Root
 * ==========================================
 */

const root = document.getElementById("root") as HTMLElement

createRoot(root).render(
  <AuthProvider
    authClient={AuthClientInstance}
    env={{ API_URL: import.meta.env.VITE_API2_URL }}
    skipRedirectCallback
    onAuthenticated={u => {
      analyticsBootstrap(u, {
        ga: {
          GTM_ID: import.meta.env.VITE_GTM_ID,
          config: {
            title: "Arduino Cloud",
            environment: import.meta.env.VITE_HF_CDN_ENV,
            site_area: "app_arduino"
          }
        },
        iubenda: {
          HF_CDN_URL: import.meta.env.VITE_HF_CDN_URL
        },
        segment: {
          TOKEN: import.meta.env.VITE_SEGMENT_TOKEN,
          SCRIPT: import.meta.env.VITE_SEGMENT_SCRIPT
        },
        profitwell: import.meta.env.PROD
      })
    }}>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <SnackbarProvider />
      <Devtools router={router} />
    </QueryClientProvider>
  </AuthProvider>
)
