import { createFileRoute, redirect } from "@tanstack/react-router"

export const Route = createFileRoute("/_no-auth/minors")({
  beforeLoad: async ({ context: { authClient }, location }) => {
    await authClient.checkSession()
    const isAuth = await authClient.isAuthenticated()

    // If authenticated, redirect to the home page.
    if (isAuth) {
      redirect({
        to: "/",
        throw: true,
        replace: true
      })
    }

    authClient.loginWithRedirect({
      appState: new URLSearchParams({ ...location.search, pathname: location.pathname }).toString(),
      authorizationParams: {
        is_minor: 1
      }
    })
  }
})
