import { lazyPrefetch } from "@bcmi-labs/art-ui/utils"
import { Fragment, useEffect, useState } from "react"

import type { FlowPropsMapper } from "@/components/modals/flows/flow"
import { FlowDialogManager } from "@/components/modals/flows/flow"
import { useModalStore } from "@/store/modals"

// Lazy load all the flow steps.
const ProSpaceNameNodal = lazyPrefetch(
  () => import("@/components/modals/flows/ProSpace/ProSpaceNameModal/ProSpaceNameModal")
)

export const ProSpaceFlowSteps = {
  name: ProSpaceNameNodal
} as const

// Declare context methods for each flow step.
const propsMap: FlowPropsMapper<typeof ProSpaceFlowSteps> = {
  name: {
    next: () => {
      useModalStore.getState().setProSpaceFlowStep(undefined)
    }
  }
}

/**
 * ProSpaceFlow manager
 */
export function ProSpaceFlow() {
  const proSpaceFlow = useModalStore(state => state.proSpaceFlow)
  const setProSpaceFlowStep = useModalStore(state => state.setProSpaceFlowStep)

  const [prefetched, setPrefetched] = useState(false)

  const Comp = proSpaceFlow?.step ? ProSpaceFlowSteps[proSpaceFlow.step] : Fragment

  useEffect(() => {
    if (proSpaceFlow.step && !prefetched) {
      Promise.all(Object.values(ProSpaceFlowSteps).map(step => step.prefetch())).then(() => setPrefetched(true))
    }

    return undefined
  }, [proSpaceFlow.step, prefetched])

  return (
    <FlowDialogManager open={!!proSpaceFlow?.step} cleanup={() => proSpaceFlow.step && setProSpaceFlowStep(undefined)}>
      {proSpaceFlow.step ? <Comp {...propsMap[proSpaceFlow.step]} /> : null}
    </FlowDialogManager>
  )
}
