import { authFetch, authXhr, getXOrganizationHeaders } from "@/api/utils"
import { unwrapResponse } from "@/api/utils/NetworkError"
import type { CustomTemplate } from "@/types"

export async function uploadMedia(xhr: XMLHttpRequest, media: File, spaceID: string) {
  // Using XHR because Fetch API doesn't support request progression yet. See: https://fetch.spec.whatwg.org/#fetch-api
  const formData = new FormData()
  formData.append("media", media, media.name)

  const req = await authXhr(
    xhr,
    {
      method: "POST",
      url: `${import.meta.env.VITE_API_MEDIA_URL}/storage/media/v1`,
      headers: getXOrganizationHeaders(spaceID)
    },
    formData
  )

  const res = await unwrapResponse<{ url: string }>(req)

  return res
}

export async function deleteMedia(id: string, spaceID: string) {
  const req = await authFetch(`${import.meta.env.VITE_API_MEDIA_URL}/storage/media/v1/${id}`, {
    method: "DELETE",
    headers: getXOrganizationHeaders(spaceID)
  })

  const res = await unwrapResponse<string>(req)
  return res
}

export async function retrieveMedia(id: string, spaceID: string) {
  const req = await authFetch(`${import.meta.env.VITE_API_MEDIA_URL}/storage/media/v1/${id}`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })

  const res = await unwrapResponse<Blob>(req)
  return res
}

export async function createTemplate(
  payload: {
    name: string
    thing_ids: string[]
    dashboard_ids: string[]
    trigger_ids: string[]
    description?: string
    image_links?: string[]
  },
  spaceID: string
) {
  const req = await authFetch(`${import.meta.env.VITE_API_MEDIA_URL}/storage/template/v1`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: getXOrganizationHeaders(spaceID)
  })

  return unwrapResponse<{
    template_id: string
    name: string
    href: string
  }>(req)
}

export async function getTemplateList(spaceID: string) {
  const req = await authFetch(`${import.meta.env.VITE_API_MEDIA_URL}/storage/template/v1/list`, {
    method: "POST",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify({
      sort: "asc",
      show_deleted: false
    })
  })

  const res = await unwrapResponse<{
    templates: Array<
      Pick<
        CustomTemplate,
        "template_id" | "name" | "description" | "created_at" | "updated_at" | "user_id" | "compatible_boards"
      > & {
        href: string
        organization_id: string
        assets: {
          thing_templates: string[]
          dashboard_templates: string[]
          trigger_templates: string[]
        }
      }
    >
  }>(req)

  return res.templates
}

export async function getTemplate(id: string, spaceID: string) {
  const req = await authFetch(`${import.meta.env.VITE_API_MEDIA_URL}/storage/template/v1/${id}`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })

  return unwrapResponse<CustomTemplate>(req)
}

export async function updateTemplate(
  templateID: string,
  payload: {
    name: string
    description?: string
    image_links?: string[]
  },
  spaceID: string
) {
  const req = await authFetch(`${import.meta.env.VITE_API_MEDIA_URL}/storage/template/v1/${templateID}`, {
    method: "PUT",
    body: JSON.stringify(payload),
    headers: getXOrganizationHeaders(spaceID)
  })

  return unwrapResponse<{
    template_id: string
    name: string
    href: string
  }>(req)
}

export async function deleteTemplate(id: string, spaceID: string) {
  const params = new URLSearchParams({ purge: "true" })
  const req = await authFetch(`${import.meta.env.VITE_API_MEDIA_URL}/storage/template/v1/${id}?${params}`, {
    method: "DELETE",
    headers: getXOrganizationHeaders(spaceID)
  })

  return unwrapResponse<{
    template_id: string
    status: string
  }>(req)
}

export async function downloadTemplate(id: string, spaceID: string) {
  const req = await authFetch(`${import.meta.env.VITE_API_MEDIA_URL}/storage/template/archive/v1/${id}`, {
    method: "GEt",
    headers: getXOrganizationHeaders(spaceID)
  })

  return unwrapResponse<Blob>(req)
}

export async function uploadTemplate(xhr: XMLHttpRequest, template: Blob, spaceID: string) {
  // Using XHR because Fetch API doesn't support request progression yet. See: https://fetch.spec.whatwg.org/#fetch-api
  const formData = new FormData()
  formData.append("template", template, "template")

  const req = await authXhr(
    xhr,
    {
      method: "POST",
      url: `${import.meta.env.VITE_API_MEDIA_URL}/storage/template/archive/v1/`,
      headers: getXOrganizationHeaders(spaceID)
    },
    formData
  )

  return unwrapResponse<{
    template_id: string
    message: string
    name: string
  }>(req)
}
