import { createFileRoute, Outlet, redirect } from "@tanstack/react-router"

import { query } from "@/api/query"
import { deploymentPath } from "@/config"
import { MY_SPACE } from "@/constants/spaces"

export const Route = createFileRoute("/_authlayout")({
  beforeLoad: async ({ context: { authClient }, location }) => {
    const customization = authClient.getCustomization()
    // If there's no customization on a custom domain, or the URL is disabled, redirect to auth-failed route
    if (deploymentPath.customKey && (!customization || !customization?.url_enabled)) {
      redirect({
        to: "/auth-failed",
        throw: true
      })
    }

    // User is not logged in and there are no auth params in the URL: redirect to login
    if (!(await authClient.isAuthenticated())) {
      const params = new URLSearchParams(window.location.search)
      params.set("pathname", window.location.pathname)
      await authClient.loginWithRedirect({ appState: params.toString() })
      return
    }

    // User is logged, but hasn't accepted TOS of custom domain: go to TOS page
    if (
      authClient.getCustomization() &&
      !(await authClient.checkTosAcceptance()) &&
      location.pathname !== "/tos-acceptance"
    ) {
      redirect({
        to: "/tos-acceptance",
        throw: true
      })
    }
  },
  loader: async ({ context: { queryClient, spaceStorage } }) => {
    const spaces = await queryClient.ensureQueryData(query.space.list)
    const space = spaces.find(sp => sp.id === spaceStorage.getSpace()) || MY_SPACE

    // Ensure some data is preloaded, so we don't have to wait for it when the user navigates to a page.
    queryClient.ensureQueryData(query.space.subscriptions(space.id))
    queryClient.ensureQueryData(query.space.subscriptions(MY_SPACE.id))

    queryClient.ensureQueryData(query.space.kits(MY_SPACE.id))

    // Only fetch space kits if the space is not the pro space
    if (space.space !== "pro") {
      queryClient.ensureQueryData(query.space.kits(space.id))
    }
  },
  component: Outlet
})
