import { createFileRoute, notFound, redirect } from "@tanstack/react-router"

import { query } from "@/api/query"
import { routeGuard } from "@/lib/routing/beforeLoad"
import { isUUIDFormat } from "@/lib/utils"
import type { CustomTemplate, Template } from "@/types"

export const Route = createFileRoute("/_authlayout/_header/templates/$id/")({
  staticData: {
    onSpaceChange: () =>
      redirect({
        to: "/templates",
        throw: true
      })
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: async ({ context: { queryClient, spaceStorage, headManager }, params, preload }) => {
    const space = spaceStorage.getSpace()

    let template: Template | CustomTemplate

    queryClient.ensureQueryData(query.other.boards)

    try {
      if (!isUUIDFormat(params.id)) {
        template = await queryClient.ensureQueryData(query["iot-templates"].single(params.id))
      } else {
        template = await queryClient.ensureQueryData(query.templates.single(params.id, space))
      }

      if (!preload) {
        headManager.push({
          title: `${template.name} | Arduino Cloud`
        })
      }
    } catch (e) {
      console.error(e) // Log the error, but don't show it to the user.
      notFound({
        throw: true
      })
    }
  }
})
