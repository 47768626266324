import { createFileRoute } from "@tanstack/react-router"

import { query } from "@/api/query"
import { routeGuard } from "@/lib/routing/beforeLoad"

type Tab = "custom" | "arduino"

type ProductSearch = {
  tab?: Tab
}

export const Route = createFileRoute("/_authlayout/_sidebar/templates/")({
  staticData: {
    title: "Templates"
  },
  validateSearch: (search: Record<string, unknown>): ProductSearch => ({
    tab: search.tab === "arduino" ? "arduino" : "custom"
  }),
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: ({ context: { queryClient } }) => {
    queryClient.ensureQueryData(query["iot-templates"].list)
  }
})
