import { createFileRoute, redirect } from "@tanstack/react-router"

export const Route = createFileRoute("/_no-auth/auth-failed/")({
  staticData: {
    title: "Auth Failed"
  },
  beforeLoad: async ({ context: { authClient } }) => {
    // If we're on a disabled custom environment, we need to skip redirect loops as the authentication library is bringing us here.
    if (authClient.getCustomization()?.url_enabled) {
      return
    }

    if (await authClient.isAuthenticated()) {
      redirect({
        to: "/",
        throw: true
      })
    }
  }
})
