import "./ErrorBoundary.scss"

import { useQueryErrorResetBoundary } from "@tanstack/react-query"
import type { ErrorComponentProps } from "@tanstack/react-router"
import { useRouter } from "@tanstack/react-router"
import { useEffect } from "react"

import { NetworkError, parseError } from "@/api/utils/NetworkError"
import { ExternalLink } from "@/components/ExternalLink"

// Check for production environment
const isProduction = process.env.NODE_ENV === "production"

export function ErrorBoundary({ error }: { error: ErrorComponentProps }) {
  let stack: string | undefined
  let url: string | undefined
  let errorMessage = "An unknown error occurred."

  // Check if the error is an instance of NetworkError

  if (error.error instanceof NetworkError) {
    const parsed = parseError(error.error)
    errorMessage = `${parsed.body.name}: ${parsed.body.detail}`
    url = parsed.body.url
    stack = error.error.stack
  } else if (error.error instanceof Error) {
    errorMessage = `${error.error.name}: ${error.error.message}`
    stack = error.error.stack
  }

  const { reset } = useQueryErrorResetBoundary()
  const router = useRouter()

  useEffect(() => {
    // Reset the error boundary when the user navigates to a new page
    const unsub = router.subscribe("onLoad", () => {
      reset()
      router.invalidate()
    })

    return unsub
  }, [reset, router])

  return (
    <div className="errorboundary">
      <img src="/images/illustrations/generic-error.svg" />
      <div className="errorboundary--text">
        <span className="font-large font-bold">{"It's just one of those days."}</span>
        <span>{"We're sorry, but something went wrong."}</span>
      </div>
      <div className="errorboundary--cta">
        <span className="font-xx-small">
          Have you tried{" "}
          <span className="link" onClick={router.invalidate}>
            reloading the page
          </span>
          ? If this is taking too long you can write us on the{" "}
          <ExternalLink className="link" href="https://forum.arduino.cc/">
            Forum
          </ExternalLink>{" "}
          or check the{" "}
          <ExternalLink className="link" href="https://status.arduino.cc/">
            Status page
          </ExternalLink>
          .
        </span>
      </div>
      {isProduction ? null : (
        <pre className="errorboundary--stack">
          <b>{errorMessage}</b>
          {url ? (
            <>
              <br />
              <i>
                {`Endpoint: ${url}`}
                <br />
              </i>
            </>
          ) : null}
          <br />
          <b>
            <i>Stack trace:</i>
          </b>
          <br />
          {stack}
        </pre>
      )}
    </div>
  )
}
