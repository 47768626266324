import { unexpectedLiteErrorMessage } from "@/constants/validation"

type NetworkErrorStruct = {
  originalError?: unknown
  url?: string
  name: string
  detail: string
  code: string
  id: string
  status: number
}
const genericErrorMessage: NetworkErrorStruct = {
  name: "Unexpected error",
  detail: unexpectedLiteErrorMessage,
  url: "",
  code: "unexpected_error",
  id: "",
  status: 500
}

export class NetworkError extends Error {
  // What we expect from the API response. We'll handle this in the parseError function, if the error is an instance of NetworkError.
  body: NetworkErrorStruct

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(body: any, url: string) {
    super()

    try {
      Error.captureStackTrace(this, NetworkError)
    } catch (error) {
      // noop
    }

    // If the body isn't adhering to the expected structure, we'll use a generic error message.
    this.body =
      typeof body !== "object"
        ? genericErrorMessage
        : {
            originalError: body,
            url,
            name: body.name || genericErrorMessage.name,
            detail: body.detail || body.message || genericErrorMessage.detail,
            code: body.code || genericErrorMessage.code,
            id: body.id || genericErrorMessage.id,
            status: body.status || genericErrorMessage.status
          }
  }
}
/**
 * Tries unwrapping the response from the API, parsing the content type and returning a typed response.
 * If the
 * @param res
 * @returns
 */

export async function unwrapResponse<T = unknown>(res: Response | XMLHttpRequest) {
  try {
    // TODO: Improve XMLHttpRequest support
    if (res instanceof XMLHttpRequest) {
      if (res.status >= 400) {
        const body = JSON.parse(res.responseText)
        throw new NetworkError(body, res.responseURL)
      }

      // ! What if this is not a JSON response?
      return JSON.parse(res.responseText) as T
    }

    if (!res.ok) {
      const body = await res.json()
      throw new NetworkError(body, res.url)
    }

    const content_type = res.headers.get("content-type")
    const blobs = [
      // ZIP files
      "application/zip",
      // Binary content
      "application/octet-stream",
      // Generic binary content type for images
      "application/image",
      // Will accept any image/* content type
      "image/"
    ]

    if (["json", "type=collection"].some(key => content_type?.includes(key))) {
      return res.json() as T
    }

    if (blobs.some(key => content_type?.includes(key))) {
      return res.blob() as T
    }

    if (content_type?.includes("text/plain")) {
      return res.text() as T
    }

    // ! Unknown content type: we weren't able to parse the response content type.
    if (content_type) {
      throw new NetworkError(
        {
          detail: "Failed to parse response content type",
          code: "invalid_content_type",
          id: "invalid_content_type",
          status: 500
        },
        res.url
      )
    }

    return {} as T
  } catch (e) {
    // If the error is already a NetworkError, we'll just pass it along.
    if (e instanceof NetworkError) throw e

    // ... unless something went really sideways, in which case we'll use a generic error message.
    throw new NetworkError(
      {
        detail: unexpectedLiteErrorMessage,
        code: "unexpected_error",
        id: "unexpected_error",
        status: 500
      },
      ""
    )
  }
}

/**
 * Parses different types of errors, extracting specific
 * properties for NetworkError and providing a generic error message for other types of errors.
 *
 * We're hoping errors are uniform, but we're not sure. In case they're not, we'll use a generic error message.
 */
export function parseError<T>(error: T) {
  if (error instanceof NetworkError) {
    const { body } = error
    const { originalError, ...rest } = body

    return {
      original: originalError,
      body: rest
    }
  }

  // We're not handling a NetworkError, so we'll return a generic error message.
  return {
    original: error,
    body: genericErrorMessage
  }
}
