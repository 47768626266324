import { unwrapResponse } from "@/api/utils/NetworkError"
import type { Board, Space, Subscriptions } from "@/types"

import { authFetch, getXOrganizationHeaders } from "./utils"

export async function getCountries() {
  try {
    const res = await fetch(`${import.meta.env.VITE_API2_URL}/countries/v1`)
    const countries = await unwrapResponse<{ code: string; name: string }[]>(res)

    // Rname code and name to value and label
    return countries.map(({ code, name }) => ({ value: code, label: name }))
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function getSubscriptions(spaceID: Space["id"]) {
  const response = await authFetch(`${import.meta.env.VITE_API2_URL}/restrictions/v1/recap/me`, {
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse<Subscriptions>(response)
}

export async function getBoardsList() {
  const response = await authFetch(`${import.meta.env.VITE_BOARDS_API_URL}`)
  const boardlist = (await unwrapResponse<{ items: Board[] }>(response)).items

  const defaultBoardDefs: Record<string, Board> = {
    "device:generic:manual": {
      name: "Manually configured device"
    } as Board
  }

  return boardlist.reduce((acc, board) => {
    acc[board.fqbn] = board
    return acc
  }, defaultBoardDefs)
}

export async function getBoardDetail(item) {
  const response = await authFetch(`${import.meta.env.VITE_BOARDS_API_URL}/byVidPid/${item.VendorID}/${item.ProductID}`)
  return unwrapResponse<Board>(response)
}
