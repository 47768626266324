import { AuthClientInstance } from "@/lib/services/instances"
import type { Space } from "@/types"

export async function getAccessToken() {
  try {
    const res = await AuthClientInstance.getTokenSilently()
    return res
  } catch {
    return null
  }
}

export async function authFetch(
  input: string,
  options: {
    [key: string]: unknown
    headers?: Record<string, string | undefined>
  } = { headers: {} }
) {
  const authToken = await getAccessToken()
  const { headers, ...restOptions } = options

  if (!authToken) {
    throw new Error("Authentication error")
  }

  const _options = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      // Filter out undefined headers
      ...Object.fromEntries(Object.entries(headers || {}).filter(([, value]) => value !== undefined))
    },
    ...restOptions
  }

  // If we're sending a FormData, don't set the Content-Type header.
  // See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest_API/Using_FormData_Objects#sending_files_using_a_formdata_object
  if (!(options.body instanceof FormData)) {
    // Pretend we're sending JSON (this is true most of the time), we can always override this later.
    _options.headers["Content-Type"] = "application/json;charset=UTF-8"
  }

  return fetch(input, _options)
}

export async function authXhr(
  xhr: XMLHttpRequest,
  open: {
    method: string
    url: string | URL
    headers?: Record<string, string | undefined>
  },
  payload: Parameters<XMLHttpRequest["send"]>[0]
) {
  const authToken = await getAccessToken()

  if (!authToken) {
    throw new Error("Authentication error")
  }

  const xhrPromise = new Promise<XMLHttpRequest>(resolve => {
    xhr.open(open.method, open.url)
    xhr.setRequestHeader("Authorization", `Bearer ${authToken}`)

    // Set headers
    if (open.headers) {
      Object.entries(open.headers).forEach(([key, value]) => {
        if (value !== undefined) xhr.setRequestHeader(key, value)
      })
    }

    const { onerror, onload } = xhr

    // eslint-disable-next-line no-param-reassign
    xhr.onerror = function fn(this, e) {
      resolve(xhr)
      onerror?.bind(this)(e)
    }

    // eslint-disable-next-line no-param-reassign
    xhr.onload = function fn(this, e) {
      onload?.bind(this)(e)
      if (xhr.status === 201) {
        resolve(xhr)
      } else {
        resolve(xhr)
      }
    }

    xhr.send(payload) // Send the data
  })

  return xhrPromise
}

export function getXOrganizationHeaders(spaceID: Space["id"]) {
  return {
    "X-Organization": spaceID === "my-cloud" ? undefined : spaceID
  }
}
