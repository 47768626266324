import { snackbar } from "@bcmi-labs/art-ui/components"
import { createFileRoute, redirect } from "@tanstack/react-router"
import { pickBy } from "remeda"

import { addMemberViaCode } from "@/api/classroom"
import { query } from "@/api/query"
import { validateSpaceCode } from "@/api/users"
import { kits as allKitsList } from "@/constants/kits"
import { MY_SPACE } from "@/constants/spaces"
import { routeGuard } from "@/lib/routing/beforeLoad"
import { useModalStore } from "@/store/modals"

type RouteSearchParams = {
  kind?: string
  join?: string
  "join-space"?: string
  "join-code"?: string
  "get-started"?: string
  renewal_id?: string
  space_id?: string
}

export const Route = createFileRoute("/_authlayout/_sidebar/")({
  staticData: {
    title: "Home"
  },
  validateSearch: (search): RouteSearchParams =>
    pickBy(
      {
        kind: search.kind as string,
        join: search.join as string,
        "join-space": search["join-space"] as string,
        "join-code": search["join-code"] as string,
        "get-started": search["get-started"] as string,
        renewal_id: search.renewal_id as string,
        space_id: search.space_id as string
      },
      v => v !== undefined
    ),
  beforeLoad: async ({ context, search }) => {
    await routeGuard(context, { allowMinor: true })
    // Parse global query params, but only if the app is not running in a custom domain.
    if (!context.authClient.getCustomization() && Object.keys(search).length > 0) {
      const spaces = await context.queryClient.ensureQueryData(query.space.list)

      const firstKey = Object.keys(search)[0] as keyof RouteSearchParams

      // Get the first key, because for now we're only planning to use one query param at a time.
      switch (firstKey) {
        // - If the user doesn't have this kit on the private space then redirect to the registration page with the code step on the private space
        // The user can get redirected here from a course website when he doesn't own the kit: redirect to registration page, code step.
        case "kind": {
          const kind = search.kind as string
          await context.queryClient.prefetchQuery({ queryKey: query.space.kits(MY_SPACE.id).queryKey })
          const kits = context.queryClient.getQueryData(query.space.kits(MY_SPACE.id).queryKey)
          if (!kits?.some(kit => kit.kind === kind) && !allKitsList[kind as string].isFree) {
            redirect({
              to: "/registration",
              search: {
                kit: kind
              }
            })
          }
          break
        }
        // Select the space provided in the query param (this isn't actually a join event)
        case "join": {
          const join = search.join as string

          // Check if user is already member of that space
          if (spaces?.some(space => space.id === join)) {
            context.spaceStorage.setSpace(join)
          }
          break
        }
        // Open the joinspace modal for code input
        case "join-space": {
          useModalStore.getState().setJoinSpaceFlowStep("join")
          break
        }
        // Start the "Get started" flow
        case "get-started":
          useModalStore.getState().setGetStartedFlowStep("start")
          break
        // Directly join a space
        case "join-code": {
          const joinCode = search["join-code"] as string

          // If there is no join code, just open the join space modal
          if (!joinCode) {
            useModalStore.getState().setJoinSpaceFlowStep("start")
            return
          }

          try {
            const { available, organization_id } = await validateSpaceCode(joinCode)

            if (!available) break

            if (spaces?.some(space => space.id === organization_id)) {
              context.spaceStorage.setSpace(organization_id)
              snackbar({ message: "You are already a member of this space." })
              break
            }

            const { status } = await addMemberViaCode({ code: joinCode })

            if (status === 201) {
              // Refresh the space list, we just joined a new space
              await context.queryClient.refetchQueries(query.space.list)
              // ! Nasty race condition. Set space after 500ms, so the sidebar has time to update
              setTimeout(() => {
                context.spaceStorage.setSpace(organization_id)
                useModalStore.getState().setJoinSpaceFlowStep("end")
              }, 500)
            }
          } catch (error) {
            console.error(error)
            useModalStore.getState().setJoinSpaceFlowStep("error")
          }
          break
        }
        case "renewal_id": {
          const renewal_id = search.renewal_id as string

          if (spaces?.some(space => space.id === renewal_id)) {
            context.spaceStorage.setSpace(renewal_id)
            snackbar({ message: "Plan activated successfully.", variant: "success" })
          }
          break
        }
        // DS store flow -> post purchase entry point
        case "space_id": {
          const id = search.space_id as string

          // Look in the list of spaces if the space exists, and if it is a pro space
          const foundSpace = spaces?.find(sp => sp.id === id)
          if (foundSpace) context.spaceStorage.setSpace(id)
          if (foundSpace && foundSpace.space === "pro") {
            useModalStore.getState().setProSpaceFlowStep("name")
          }

          break
        }
        default:
          break
      }

      // Purge the queryparams from the URL
      redirect({
        search: {},
        throw: true
      })
    }
  },
  loader: ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()
    queryClient.ensureQueryData(query.device.list(space))
    queryClient.ensureQueryData(query.thing.list(space))
    queryClient.ensureQueryData(query.space.members(space))
    queryClient.ensureQueryData(query.dashboard.list(space))
  }
})
