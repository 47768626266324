import { unwrapResponse } from "@/api/utils/NetworkError"
import type {
  ApiKey,
  Dashboard,
  Device,
  DeviceEventsResponse,
  DeviceStatusResponse,
  Space,
  Template,
  TemplateCardType,
  Thing,
  Trigger,
  VariableType
} from "@/types"

import { authFetch, getXOrganizationHeaders } from "./utils"

export async function getAPIKeys() {
  const response = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v1/clients`)
  return unwrapResponse<ApiKey[]>(response)
}

export async function updateAPIKeys(name: ApiKey["name"], id: ApiKey["id"]) {
  const response = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v1/clients/${id}`, {
    method: "PATCH",
    body: JSON.stringify({
      name
    })
  })
  return unwrapResponse(response)
}

export async function regenerateSecretAPIKeys(id: ApiKey["id"]) {
  const response = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v1/clients/${id}/secret`, {
    method: "POST"
  })
  return unwrapResponse<ApiKey>(response)
}

export async function removeAPIKeys(id: ApiKey["id"]) {
  const response = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v1/clients/${id}`, {
    method: "DELETE"
  })
  return unwrapResponse(response)
}

export async function createAPIKeys(name: ApiKey["name"], spaceID: Space["id"]) {
  const response = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v1/clients`, {
    method: "PUT",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify({
      name
    })
  })
  return unwrapResponse<ApiKey>(response)
}

export async function getThingsList(
  spaceID: Space["id"],
  params?: {
    show_properties?: string
  } & Record<string, string>
) {
  const queryParams = new URLSearchParams(params)
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/things?${queryParams}`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse<Thing[]>(res)
}

export async function getThingsTags(spaceID: Space["id"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/things_tags`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse<string[]>(res)
}

export async function duplicateThing(spaceID: Space["id"], id: Thing["id"], payload: { name: Thing["name"] }) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/things/${id}/clone`, {
    method: "PUT",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify({ ...payload, include_tags: true })
  })
  return unwrapResponse<Thing>(res)
}

export async function deleteThing(spaceID: Space["id"], payload: { id: Thing["id"] }) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/things/${payload.id}`, {
    method: "DELETE",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse(res)
}

export async function deleteThingSketch(spaceID: Space["id"], payload: { id: string }) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/things/${payload.id}/sketch`, {
    method: "DELETE",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse(res)
}

export async function updateThing(
  spaceID: Space["id"],
  thingID: Thing["id"],
  payload: Partial<{ name: Thing["name"]; device_id: Thing["device_id"] }>,
  params?: Record<string, string>
) {
  const queryParams = new URLSearchParams(params)
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/things/${thingID}?${queryParams}`, {
    method: "POST",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify(payload)
  })
  return unwrapResponse<Thing>(res)
}

export async function getThingsPropertyTypes() {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v1/property_types`, {
    method: "GET"
  })
  return unwrapResponse<VariableType[]>(res)
}

export async function getDashboardsList(spaceID: Space["id"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/dashboards`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse<Dashboard[]>(res)
}

export async function cloneDashboard(spaceID: Space["id"], id: Dashboard["id"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/dashboards/${id}/clone`, {
    method: "PUT",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify({})
  })
  return unwrapResponse(res)
}

export async function deleteDashboard(spaceID: Space["id"], payload: { id: Dashboard["id"] }) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/dashboards/${payload.id}`, {
    method: "DELETE",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse(res)
}

export async function removeSharedDashboardUser(
  spaceID: Space["id"],
  payload: { id: Dashboard["id"]; userid: string }
) {
  const res = await authFetch(
    `${import.meta.env.VITE_API2_URL}/iot/v2/dashboards/${payload.id}/shares/${payload.userid}`,
    {
      method: "DELETE",
      headers: getXOrganizationHeaders(spaceID)
    }
  )
  return unwrapResponse(res)
}

export async function updateDashboard(spaceID: Space["id"], dashboardID: Dashboard["id"], payload: Dashboard) {
  // Change the payload so that widgets.widget.variables is a string[] of the variable IDs
  const dashboard = {
    ...payload,
    widgets: payload.widgets?.map(widget => ({ ...widget, variables: widget.variables?.map(variable => variable.id) }))
  }

  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/dashboards/${dashboardID}`, {
    method: "PUT",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify(dashboard)
  })
  return unwrapResponse<Dashboard>(res)
}

export async function getDevicesList(spaceID: Space["id"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/devices`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })
  return (await unwrapResponse<Device[]>(res)).sort((a, b) => a.name.localeCompare(b.name))
}

export async function getDevice(spaceID: Space["id"], id: Device["id"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/devices/${id}`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse<Device>(res)
}

export async function getDeviceEvents(spaceID: Space["id"], id: Device["id"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/devices/${id}/events`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })
  return (await unwrapResponse<DeviceEventsResponse>(res)).events
}

export async function getDeviceStatus(spaceID: Space["id"], id: Device["id"], params: { limit?: number; start: Date }) {
  const queryParams = new URLSearchParams({ limit: String(params.limit || 20000), start: params.start.toISOString() })
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/devices/${id}/status?${queryParams}`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })
  return (await unwrapResponse<DeviceStatusResponse>(res)).events
}

export async function deleteDevice(spaceID: Space["id"], payload: { id: Device["id"] }) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/devices/${payload.id}`, {
    method: "DELETE",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse(res)
}

export async function updateDevice(
  spaceID: Space["id"],
  deviceID: Device["id"],
  payload: Partial<{ name: Dashboard["name"] }>
) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/devices/${deviceID}`, {
    method: "POST",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify(payload)
  })
  return unwrapResponse<Device>(res)
}

export async function addWebhook(
  spaceID: Space["id"],
  deviceID: Device["id"],
  payload: { active: boolean; uri: string }
) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/devices/${deviceID}/webhooks`, {
    method: "PUT",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify(payload)
  })
  return unwrapResponse<{
    active: boolean
    id: string
    uri: string
  }>(res)
}

export async function removeWebhook(spaceID: Space["id"], deviceID: Device["id"], webhookid: string) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/devices/${deviceID}/webhooks/${webhookid}`, {
    method: "DELETE",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse(res)
}

export async function getLoraMessages(spaceID: Space["id"], deviceID: Device["id"]) {
  const start = new Date().toISOString()
  const res = await authFetch(
    `${import.meta.env.VITE_API2_URL}/iot/v2/devices/${deviceID}/properties/lora:uplink?start=${start}&limit=${100}`,
    {
      method: "GET",
      headers: getXOrganizationHeaders(spaceID)
    }
  )
  return unwrapResponse<{
    id: string
    last_evaluated_key: string
    name: string
    values: Array<{
      created_at: string
      value: {
        payload: string
        seqno: number
        statistics: Record<string, number>
      }
    }>
  }>(res)
}

export async function sendLoraMessage(
  spaceID: Space["id"],
  deviceID: Device["id"],
  payload: {
    properties: Array<{
      name: "lora:downlink"
      value: string
      type: "hex" | "text"
    }>
  }
) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v2/devices/${deviceID}/properties`, {
    method: "PUT",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify(payload)
  })
  return unwrapResponse(res)
}

export async function getTriggersList(spaceID: Space["id"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v1/triggers`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse<Trigger[]>(res)
}

export async function deleteTrigger(spaceID: Space["id"], payload: { id: Trigger["id"] }) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v1/triggers/${payload.id}`, {
    method: "DELETE",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse(res)
}

export async function updateTrigger(spaceID: Space["id"], payload: Partial<Trigger>) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v1/triggers/${payload.id}`, {
    method: "PATCH",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify(payload)
  })
  return unwrapResponse<Trigger>(res)
}

export async function getIoTTemplates() {
  const res = await authFetch(`${import.meta.env.VITE_TEMPLATE_URL}/templates.json`)
  return unwrapResponse<TemplateCardType[]>(res)
}

export async function getTemplatesByName(name: string) {
  const res = await authFetch(`${import.meta.env.VITE_TEMPLATE_URL}/templates/${name}/template.json`)
  return unwrapResponse<Template>(res)
}
