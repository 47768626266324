import { MY_CLOUD_SPACE } from "@bcmi-labs/cloud-sidebar"
import { queryOptions } from "@tanstack/react-query"

import { getSpaceKits, getSpacesClean, getSpacesInviteCode, getUsersList } from "@/api/classroom"
import { getBoardsList, getCountries, getSubscriptions } from "@/api/common"
import { getSketchesFiles, getSketchesList } from "@/api/create"
import { getCustomization } from "@/api/customization"
import { getKits } from "@/api/education"
import {
  getAPIKeys,
  getDashboardsList,
  getDevice,
  getDeviceEvents,
  getDevicesList,
  getDeviceStatus,
  getIoTTemplates,
  getLoraMessages,
  getTemplatesByName,
  getThingsList,
  getThingsPropertyTypes,
  getThingsTags,
  getTriggersList
} from "@/api/iot"
import { getTriggersHistoryList } from "@/api/notifications"
import { getOtaListByDeviceID } from "@/api/ota"
import { getTemplate, getTemplateList, retrieveMedia } from "@/api/storage"
import { MY_SPACE } from "@/constants/spaces"
import type { Device, Space, Type, VariableType } from "@/types"

/**
 * Centralized query functions
 * Every object exports the relative query function and query key.
 */

export const query = {
  device: {
    /** Retrieves the list of devices for the given space. */
    list: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`devices/list/${id}`],
        queryFn: () => getDevicesList(id)
      }),
    /** Retrieves the requested device in the given space. */
    single: {
      info: (id: Space["id"], deviceId: Parameters<typeof getDevice>[1]) =>
        queryOptions({
          queryKey: [`devices/single/${id}/${deviceId}`],
          queryFn: () => getDevice(id, deviceId)
        }),
      events: (id: Space["id"], deviceId: Parameters<typeof getDeviceEvents>[1]) =>
        queryOptions({
          queryKey: [`devices/single/${id}/${deviceId}/events`],
          queryFn: () => getDeviceEvents(id, deviceId)
        }),
      status: (
        id: Space["id"],
        deviceId: Parameters<typeof getDeviceStatus>[1],
        params: Parameters<typeof getDeviceStatus>[2]
      ) =>
        queryOptions({
          queryKey: [`devices/single/${id}/${deviceId}/status`, params.start],
          queryFn: () => getDeviceStatus(id, deviceId, params)
        }),
      otaList: (id: Space["id"], deviceId: Device["id"]) =>
        queryOptions({
          queryKey: [`devices/single/${id}/${deviceId}/ota`],
          queryFn: () => getOtaListByDeviceID(id, deviceId)
        })
    },
    lora: {
      getMessages: (id: Space["id"], deviceId: Parameters<typeof getLoraMessages>[1]) =>
        queryOptions({
          queryKey: [`devices/lora/${id}/${deviceId}/messages`],
          queryFn: () => getLoraMessages(id, deviceId)
        })
    }
  },
  dashboard: {
    /** Retrieves the list of dashboards for the given space. */
    list: (id: Space["id"]) =>
      queryOptions({ queryKey: [`dashboard/list/${id}`], queryFn: () => getDashboardsList(id) })
  },
  sketch: {
    /** Retrieves the list of sketches for the given space. */
    list: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`sketches/list/${id}`],
        queryFn: () => getSketchesList(id)
      }),
    /** Retrieves the sketch file tree for the given space. */
    files: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`sketches/files/${id}`],
        queryFn: () => getSketchesFiles(id)
      })
  },
  thing: {
    /** Retrieves the list of things for the given space. */
    list: (id: Space["id"], params?: Parameters<typeof getThingsList>[1]) =>
      queryOptions({
        queryKey: [`things/list/${id}`],
        queryFn: () => getThingsList(id, params)
      }),
    /** Retrieves the list of tags for the given space. */
    tags: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`things/tags/${id}`],
        queryFn: () => getThingsTags(id)
      })
  },
  trigger: {
    list: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`triggers/list/${id}`],
        queryFn: () => getTriggersList(id)
      }),
    history: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`triggers/history/${id}`],
        queryFn: () => getTriggersHistoryList(id)
      })
  },
  space: {
    list: queryOptions({
      queryKey: [`spaces/list`],
      queryFn: () => getSpacesClean()
    }),
    // TODO: Figure out a way to prevent errors from being thrown when an enterprise space is provided
    kits: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`spaces/kits/${id}`],
        queryFn: () => (id === MY_CLOUD_SPACE.id ? getKits() : getSpaceKits(id))
      }),
    subscriptions: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`spaces/subscriptions/${id}`],
        queryFn: () => getSubscriptions(id)
      }),
    /** Retrieves the list of members for the given space. */
    members: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`members/list/${id}`],
        queryFn: () => getUsersList(id)
      }),
    /** Retrieves the invite code for the given space. */
    inviteCode: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`space/code/${id}`],
        queryFn: () => getSpacesInviteCode(id)
      })
  },
  user: {
    /** Retrieves the list of subscriptions for the current user. */
    subscriptions: queryOptions({
      queryKey: [`subscriptions`],
      queryFn: () => getSubscriptions(MY_SPACE.id)
    }),
    /** Retrieves the list of API keys for the current user. */
    apiKeys: queryOptions({
      queryKey: [`apiKeys`],
      queryFn: () => getAPIKeys()
    })
  },
  "iot-templates": {
    /** Retrieves the list of IoT templates. */
    list: queryOptions({
      queryKey: [`iot-templates/list`],
      queryFn: () => getIoTTemplates()
    }),
    /** Retrieves the requested IoT template. */
    single: (name: string) =>
      queryOptions({
        queryKey: [`iot-templates/single/${name}`],
        queryFn: () => getTemplatesByName(name)
      })
  },
  templates: {
    list: (spaceId: string) =>
      queryOptions({
        queryKey: [`templates/${spaceId}/list`],
        queryFn: () => getTemplateList(spaceId)
      }),
    single: (id: string, spaceId: string) =>
      queryOptions({
        queryKey: [`templates/${spaceId}/${id}`],
        queryFn: () => getTemplate(id, spaceId)
      })
  },
  media: {
    getMedia: (id: string, spaceId: string) =>
      queryOptions({
        queryKey: [`media/${spaceId}/${id}`],
        queryFn: () => retrieveMedia(id, spaceId),
        staleTime: Infinity
      })
  },
  other: {
    /** Retrieves the list of countries.
     * By default, this query will never be stale.
     */
    countries: queryOptions({
      queryKey: [`countries`],
      queryFn: () => getCountries(),
      staleTime: Infinity
    }),
    /** Retrieves the list of boards.
     * By default, this query will never be stale.
     */
    boards: queryOptions({
      queryKey: [`boards`],
      queryFn: () => getBoardsList(),
      staleTime: Infinity
    }),
    customization: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`customizations/${id}`],
        queryFn: () => allowErrorFallback(getCustomization(id), {} as Awaited<ReturnType<typeof getCustomization>>)
      }),
    property_types: queryOptions({
      queryKey: [`property_types`],
      queryFn: () => getThingsPropertyTypes(),
      select: response => response.reduce((o, i) => ({ ...o, [i.type]: i }), {} as Partial<Record<Type, VariableType>>),
      staleTime: Infinity
    })
  }
}

/*
 * =============================================================================
 * Query utilities
 * ============================================================================
 */

/**
 * Allows a query to fail and fallback to a default value, without throwing an error.
 * @returns
 */
async function allowErrorFallback<T>(q: Promise<T>, fallback: T): Promise<T> {
  try {
    return await q
  } catch (e) {
    // Print the original error, but return the fallback value
    console.error(e)
    return fallback
  }
}
