import { createFileRoute } from "@tanstack/react-router"

import { query } from "@/api/query"
import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_sidebar/things/")({
  staticData: {
    title: "Things"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()

    queryClient.ensureQueryData(query.thing.list(space, { show_properties: "true" }))
    queryClient.ensureQueryData(query.thing.tags(space))
    queryClient.ensureQueryData(query.device.list(space))
    queryClient.ensureQueryData(query.sketch.list(space))
    queryClient.ensureQueryData(query.space.members(space))
  }
})
