import { createFileRoute, redirect } from "@tanstack/react-router"

import { query } from "@/api/query"
import { MY_SPACE } from "@/constants/spaces"
import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_sidebar/courses/")({
  staticData: {
    title: "Courses"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context, { allowMinor: true })

    const spaces = await context.queryClient.ensureQueryData(query.space.list)
    const space = spaces.find(sp => sp.id === context.spaceStorage.getSpace()) || MY_SPACE

    // If the space is not an enterprise space, redirect to root
    if (space.space === "pro") {
      redirect({
        to: "/",
        throw: true
      })
    }
  },
  loader: async ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()
    queryClient.ensureQueryData(query.space.kits(space))
    queryClient.ensureQueryData(query.space.kits(MY_SPACE.id))
  },
  shouldReload: () => true
})
