import type { AgentDevice } from "arduino-create-agent-js-client"
import { create } from "zustand"

import type { getStartedFlowSteps } from "@/components/modals/flows/GetStarted"
import type { joinSpaceflowSteps } from "@/components/modals/flows/JoinSpace"
import type { ProSpaceFlowSteps } from "@/components/modals/flows/ProSpace"
import type { Board } from "@/types"

// Remember to import flow descriptors as types, to avoid creating circular dependencies!
// This way we won't leak anything during runtime.
interface ModalStoreState {
  proSpaceFlow: {
    step: keyof typeof ProSpaceFlowSteps | undefined
  }
  setProSpaceFlowStep: (step: keyof typeof ProSpaceFlowSteps | undefined) => void
  joinSpaceFlow: {
    step: keyof typeof joinSpaceflowSteps | undefined
  }
  setJoinSpaceFlowStep: (step: keyof typeof joinSpaceflowSteps | undefined) => void
  getStartedFlow: {
    step: keyof typeof getStartedFlowSteps | undefined
    store?: {
      board: Board
      serial: AgentDevice
    }
  }
  setGetStartedFlowStep: (step: keyof typeof getStartedFlowSteps | undefined) => void
  setGetStartedFlowStore: (store: ModalStoreState["getStartedFlow"]["store"]) => void
}

/**
 * Modal store.
 */
export const useModalStore = create<ModalStoreState>()(set => ({
  proSpaceFlow: {
    step: undefined
  },
  setProSpaceFlowStep: step => set(state => ({ ...state, proSpaceFlow: { step } })),
  joinSpaceFlow: {
    step: undefined
  },
  setJoinSpaceFlowStep: step => set(state => ({ ...state, joinSpaceFlow: { step } })),
  getStartedFlow: {
    step: undefined
  },
  setGetStartedFlowStep: step =>
    set(state => ({
      ...state,
      // Wipe the store if the step is undefined.
      getStartedFlow: { step, store: step === undefined ? undefined : state.getStartedFlow.store }
    })),
  setGetStartedFlowStore: store =>
    set(state => ({
      ...state,
      getStartedFlow: {
        ...state.getStartedFlow,
        store
      }
    }))
}))
