import { createFileRoute } from "@tanstack/react-router"

import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_sidebar/resources/")({
  staticData: {
    title: "Resources"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  }
})
