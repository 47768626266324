import { unwrapResponse } from "@/api/utils/NetworkError"
import { AuthClientInstance } from "@/lib/services/instances"
import type { Sketch, SketchFile, SketchFileData, SketchJson, Space } from "@/types"

import { authFetch, getXOrganizationHeaders } from "./utils"

export async function createSketch(space: Space["id"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/sketches`, {
    method: "PUT",
    body: JSON.stringify({
      user_id: "me",
      path: `new_sketch_${Date.now()}`,
      ino: "LyoNDSovDQ12b2lkIHNldHVwKCkgew0gICAgDX0NDXZvaWQgbG9vcCgpIHsNICAgIA19DQ=="
    }),
    headers: getXOrganizationHeaders(space)
  })
  return unwrapResponse<{ id: string }>(res)
}

export async function getSketchesList(spaceID: Space["id"]) {
  const userID = (await AuthClientInstance.getUser())?.["http://arduino.cc/id"]
  if (!userID) {
    return []
  }

  const params = new URLSearchParams({ user_id: userID || "", limit: "1000" }) // This is actually required
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/sketches?${params}`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })
  const data = await unwrapResponse<{ sketches: Sketch[] }>(res)
  return data.sketches
}

export async function getSketchesFiles(spaceID: Space["id"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/files/d/$HOME/sketches_v2?deep=true`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })

  // If failed, this usually means the user doesn't have sketches in the VFS. So return an empty array.
  if (res.status === 404) {
    return [] as SketchFile[]
  }

  return unwrapResponse<SketchFile[]>(res)
}

export async function getSketchFile(spaceID: Space["id"], path: Sketch["path"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/files/f/${path}`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse<SketchFileData>(res)
}

export async function deleteSketch(spaceID: Space["id"], path: Sketch["path"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/files/d/${path}`, {
    method: "DELETE",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse(res)
}

export async function createSketchFolder(spaceID: Space["id"], path: Sketch["path"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/files/d/$HOME/sketches_v2/${path}`, {
    method: "POST",
    headers: getXOrganizationHeaders(spaceID)
  })
  return unwrapResponse(res)
}

export async function createSketchFile(spaceID: Space["id"], path: Sketch["path"], data: string) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/files/f/$HOME/sketches_v2/${path}`, {
    method: "POST",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify({ data })
  })
  return unwrapResponse(res)
}

export async function createSketchLibrary(spaceID: Space["id"], body: FormData) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/libraries`, {
    method: "POST",
    headers: { ...getXOrganizationHeaders(spaceID) },
    body
  })
  return unwrapResponse(res)
}

export async function createInoSketch(
  spaceID: Space["id"],
  payload: {
    user_id: string
    path: string
    ino: string
  },
  json?: SketchJson
) {
  // Add JSON data into payload
  const reworkedPayload = json
    ? {
        ...payload,
        secrets: {
          data: json.secrets
        },
        libraries: json.included_libs,
        board_fqbn: json.cpu?.fqbn || undefined,
        board_name: json.cpu?.name || undefined,
        board_type: json.cpu?.type || undefined
      }
    : payload

  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/sketches`, {
    method: "PUT",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify(reworkedPayload)
  })
  return unwrapResponse<{ id: string }>(res)
}

export async function editSketch(spaceID: Space["id"], sketchId: Sketch["id"], payload: Partial<Sketch>) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v2/sketches/${sketchId}`, {
    method: "POST",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify(payload)
  })
  return unwrapResponse(res)
}

export async function moveSketch(spaceID: Space["id"], payload: { from: string; to: string }) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v3/files/mv`, {
    method: "POST",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify(payload)
  })
  return unwrapResponse(res)
}

export async function copySketch(spaceID: Space["id"], payload: { from: string; to: string }) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/create/v3/files/cp`, {
    method: "POST",
    headers: getXOrganizationHeaders(spaceID),
    body: JSON.stringify(payload)
  })
  return unwrapResponse(res)
}
