import { createFileRoute, Outlet } from "@tanstack/react-router"
import { memo } from "react"

import { NoneLayout } from "@/components/layouts/none"

export const Route = createFileRoute("/_no-auth")({
  component: memo(Layout)
})

function Layout() {
  return (
    <NoneLayout>
      <Outlet />
    </NoneLayout>
  )
}
