import { createFileRoute, notFound, redirect } from "@tanstack/react-router"

import { query } from "@/api/query"
import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_header/templates/$id/edit/")({
  staticData: {
    title: "Edit Template",
    onSpaceChange: () =>
      redirect({
        to: "/templates",
        throw: true
      })
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: async ({ context: { queryClient, spaceStorage }, params }) => {
    const space = spaceStorage.getSpace()

    queryClient.ensureQueryData(query.other.boards)

    try {
      queryClient.ensureQueryData(query.templates.single(params.id, space))
    } catch (e) {
      console.error(e) // Log the error, but don't show it to the user.
      notFound({
        throw: true
      })
    }
  }
})
