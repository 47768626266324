import "./None.scss"

import { cx } from "class-variance-authority"
import type { ComponentProps } from "react"

export function NoneLayout(props: ComponentProps<"div">) {
  const { children, className, ...rest } = props

  return (
    <div className={cx("None", className)} {...rest}>
      <div className="None__content">{children}</div>
    </div>
  )
}
