import { AuthClient } from "@bcmi-labs/art-auth"
import { SpaceStorage } from "@bcmi-labs/cloud-sidebar"
import { QueryClient } from "@tanstack/react-query"
import { createHead } from "unhead"

import { auth0Config, deploymentPath } from "@/config"

// Create a global head instance
export const headManager = createHead()

/** Global queryClient */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 20000 // 20 seconds
    }
  }
})

// Create a new instance of the AuthClient
export const AuthClientInstance = deploymentPath.customKey
  ? await AuthClient.createCloudClient(auth0Config, deploymentPath.customKey, {
      API_URL: import.meta.env.VITE_API2_URL
    }).catch(() =>
      // Bailing out! We can't create the client, so we redirect to the default app domain (which will error out).
      AuthClient.create(auth0Config)
    )
  : AuthClient.create(auth0Config)

// Crete a new instance of the space storage
export const SpaceStorageInstance = new SpaceStorage(
  import.meta.env.VITE_COOKIES_DOMAIN,
  // Try binding the space storage to the customer space if we have a fourth level domain. Otherwise, fallback to the default domain.
  deploymentPath.customKey ? `${deploymentPath.customKey}-arduino` : undefined
)
