import { createFileRoute } from "@tanstack/react-router"

import { query } from "@/api/query"
import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_sidebar/triggers/")({
  staticData: {
    title: "Triggers"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()

    queryClient.ensureQueryData(query.trigger.list(space))
    queryClient.ensureQueryData(query.trigger.history(space))
  }
})
