import { createFileRoute } from "@tanstack/react-router"

import { query } from "@/api/query"
import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_sidebar/members/")({
  staticData: {
    title: "Members"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context, { allowPrivateSpace: false })
  },
  loader: ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()
    queryClient.ensureQueryData(query.space.members(space))
  }
})
