import type { AuthClientOptions } from "@bcmi-labs/art-auth"

/**
 * The deployment path configuration, calculated at runtime (IIFE).
 */
export const deploymentPath: {
  /** The custom 4th level domain, used for customization purposes */
  customKey: string | undefined
  /** The root of the application, adapted to the environment (used for external redirection on the same environment domain) */
  extRoot: string
} = (() => {
  // Check if we are on an Arduino domain (e.g. app.arduino.cc)
  const isArduinoDomain = window.location.origin.includes(`app.${import.meta.env.VITE_DOMAIN}.cc`)

  return {
    // If we are on an Arduino domain, check if it's a custom one.
    customKey:
      isArduinoDomain && window.location.hostname.split(".").length === 4
        ? window.location.hostname.split(".")[0]
        : undefined,
    // If we're not running on a real Arduino domain, use the VITE_DOMAIN environment variable. Else, we can simply use the current origin.
    extRoot: !isArduinoDomain ? `https://app.${import.meta.env.VITE_DOMAIN}.cc` : window.location.origin
  }
})()

// Store the Auth0 configuration
export const auth0Config: AuthClientOptions = {
  config: {
    domain: import.meta.env.VITE_AUTH_DOMAIN,
    clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin,
      scope: "openid profile email"
    },
    legacySameSiteCookie: false,
    authorizeTimeoutInSeconds: 5
  }
}
