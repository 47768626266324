import { createFileRoute } from "@tanstack/react-router"

import { query } from "@/api/query"
import { MY_SPACE } from "@/constants/spaces"
import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_sidebar/integrations/")({
  staticData: {
    title: "Integrations"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()

    queryClient.ensureQueryData(query.space.subscriptions(space))
    queryClient.ensureQueryData(query.space.subscriptions(MY_SPACE.id))
  }
})
